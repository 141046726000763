
import {
    defineComponent, PropType, reactive,
    Ref, ref
} from 'vue';
import { byteLength } from 'byte-length';
import { peopleDeviceApi } from '@/api';
import { DepartmentForm } from './all.type';

const checkDepartmentName: RuleMethod = (rule, value, callback) => {
    if (byteLength(value as string) > 63) {
        return callback(
            new Error(
                WordList.RuleLengthExceeds63.format(
                    WordList.ProperAllTextDepartmentName,
                    WordList.ProperAllTextDepartmentName
                )
            )
        );
    }
    return callback();
};

const rules = {
    DepartmentName: [
        {
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextDepartmentName),
            trigger: 'blur'
        },
        {
            validator: checkDepartmentName,
            trigger: 'blur'
        }
    ]
};
export default defineComponent({
    emits: ['close', 'success'],
    props: {
        initForm: {
            type: Object as PropType<DepartmentForm>,
            required: true
        },
        operate: {
            type: String as PropType<OperateType>,
            required: true
        }
    },
    setup(props, { emit }) {
        const titles = {
            add: WordList.ProperAllTextNewDepartment,
            edit: WordList.EditDepartment
        };
        const form: Ref< any > = ref(null);
        const formData = reactive({ ...props.initForm });
        const api = {
            add: peopleDeviceApi.addDepartment,
            edit: peopleDeviceApi.editDepartment
        };
        const submit = () => {
            form.value.validate((valid: boolean) => {
                if (valid) {
                    api[props.operate]({
                        Name: formData.DepartmentName,
                        ID: formData.ID,
                        Floor: formData.Floor.includes('all') ? 'all' : (formData.Floor as string[]).join(';')
                    }, () => {
                        emit('close');
                        emit('success');
                    });
                }
            });
        };

        // 楼层数据
        const floorOptions = ref<{key: string;value: string}[]>([{
            key: 'all',
            value: WordList.AllFloors
        }]);
        for (let i = 1; i < 129; i += 1) {
            floorOptions.value.push({
                key: i.toString(),
                value: i.toString()
            });
        }

        // 最多只能选10个floor层
        function changeFloor() {
            if (formData.Floor.length > 10) {
                (formData.Floor as string[]).pop();
            }
        }
        return {
            form,
            formData,
            submit,
            rules,
            titles,
            floorOptions,
            changeFloor
        };
    }
});
