import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_cus_option = _resolveComponent("cus-option")
  const _component_cus_select = _resolveComponent("cus-select")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.titles[_ctx.operate],
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "form",
        "label-position": _ctx.$formLabelPosition,
        model: _ctx.formData,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextDepartmentName,
            prop: "DepartmentName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.DepartmentName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.DepartmentName = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.Floor
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cus_select, {
                multiple: "",
                modelValue: _ctx.formData.Floor,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.Floor = $event)),
                onChange: _ctx.changeFloor,
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.floorOptions, (item) => {
                    return (_openBlock(), _createBlock(_component_cus_option, {
                      key: item.key,
                      label: item.value,
                      value: item.key
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["label-position", "model", "rules"])
    ]),
    _: 1
  }, 8, ["title", "onSubmit"]))
}